function checkRadioForId(id) {
  document.getElementById(id).checked = true;
}

function focusInput(id) {
  document.getElementById(id).focus();
}

function setValue(value) {
  document.getElementById('value').value = +value;
}

function setRequired(id, value) {
  const element = document.getElementById(id);
  if (!value) {
    element.removeAttribute('required');
    return;
  }
  element.setAttribute('required', '');
}

function onCustomValueClick() {
  const customValue = document.getElementById('custom-eur-input').value;
  focusInput('custom-eur-input');
  setRequired('custom-eur-input', true);
  setValue(customValue);
}

function onFixedValueClick(value) {
  setRequired('custom-eur-input', false);
  setValue(value);
}
